<template>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="sortedItems"
    :search-input.sync="search"
    placeholder="Введите код организации"
    :rules="$attrs['rules']"
    label="Организация"
    small-chips
    outlined
    :loading="loading"
    item-text="Name"
    @click="hideMenu = null"
    :menu-props="{ ...(hideMenu && { value: false }) }"
    ref="combobox"
    v-mask="'NNNNNN'"
    :error-messages="modelError"
    @focus="$emit('update:modelError', null)"
    tabindex="2"
  >
    <template v-slot:selection="{ item }"> {{ item.Name }} &ensp; </template>
    <template v-slot:item="{ index, item }">
      <v-chip :color="`${item.color} lighten-3`" dark label small>
        {{ item.Name }} {{ index }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-list-item-action @click.stop>
        <v-btn icon @click.stop.prevent="del(item)">
          <m-icon icon="mdi-delete" middle></m-icon>
        </v-btn>
      </v-list-item-action>
    </template>
    <template
      v-slot:append-item
      v-if="$refs.combobox && $refs.combobox.computedItems.length"
    >
      <div class="text-center">
        <v-btn text color="primary" class="mt-2" @click="clickAdd()"
          >Добавить организацию</v-btn
        >
      </div>
    </template>
  </v-combobox>
</template>

<script>
import AuthService from "../../../services/AuthService";
import Cookies from "js-cookie";
import MIcon from "../../../components/base/BaseIcon.vue";

export default {
  components: {
    MIcon,
  },
  props: {
    modelError: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    hideMenu: null,
    colSymbol: 6,
    loading: false,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    editing: null,
    editingIndex: -1,
    items: [
      { header: "Выберите организацию или введите код организации" },
      ...(Cookies.get("owners") ? JSON.parse(Cookies.get("owners")) : []),
    ],
    nonce: 1,
    model: Cookies.get("ownerLastSelect")
      ? JSON.parse(Cookies.get("ownerLastSelect"))
      : null,
    x: 0,
    search: null,
    y: 0,
  }),

  computed: {
    sortedItems() {
      return [...this.items].sort(function (a, b) {
        if (b.header) return 1;
        if (a.header) return 0;
        return a?.Name.localeCompare(b.Name);
      });
    },
  },

  watch: {
    search: function (val) {
      if (val?.length !== 6) return;
      this.$emit("update:modelError", null);

      const findCompany = this.items.find((e) => e.Code === val);
      if (findCompany) {
        this.selectCompany(findCompany);
        return;
      }
      this.loadNew(val);
    },
    items: function (val) {
      const arr = [...val];
      arr.splice(0, 1);
      Cookies.set("owners", JSON.stringify(arr), { expires: 1500 });
    },
    model: {
      immediate: true,
      handler: function (val) {
        if (typeof val !== "object") {
          this.$nextTick(() => {
            this.model = null;
          });
          return;
        }
        this.$emit("update:modelError", null);
        this.$emit("input", val?.Code ?? "");
        if (!val) return;
        Cookies.set("ownerLastSelect", JSON.stringify(val), { expires: 1500 });
      },
    },
  },
  methods: {
    clickAdd() {
      this.model = null;
      this.$nextTick(() => {
        this.$refs.combobox.focus();
      });
    },
    selectCompany(company) {
      this.$emit("update:modelError", null);
      this.model = company;

      // Скрывать меню сразу как будет введена организация
      this.hideMenu = true;
      document.activeElement.blur();
    },
    async loadNew(Code) {
      this.loading = true;
      try {
        const { data } = await AuthService.getOwner(Code);
        if (data) {
          data.color =
            this.colors[Math.floor(Math.random() * this.colors.length)];
          this.items.push(data);
          this.search = null;
          this.selectCompany(this.items[this.items.length - 1]);
        }
      } catch (error) {
        this.$emit("update:modelError", error.response.data.Message);
      }
      this.loading = false;
    },
    del(item) {
      if (this.model && item.Id === this.model.Id) this.model = null;

      if (confirm('Удалить из списка организацию: "' + item.Name + '" ?'))
        this.items.splice(
          this.items.findIndex((e) => e.Name === item.Name),
          1
        );
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val !== null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
          -1 || item.Code.toString().indexOf(query.toString()) > -1
      );
    },
  },
};
</script>
